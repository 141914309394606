import React from 'react'
import { Link, graphql } from 'gatsby'

import styled from '@emotion/styled'
import { breakpoints } from '~/utils/styles'

import Img from 'gatsby-image'

import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'

const ImageWrap = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 5rem);
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
  img {
    height: 100%;
    object-fit: contain;
    object-position: 50% 100%;
    width: 100%;
  }
`

const IndexPage = ({ data }) => {
  // const { edges: products } = data.allShopifyProduct
  // const mappedProducts = products.map(product => product.node)
  const { url: image } = data.allPrismicHomepage.nodes[0].data.main_image
  return (
    <>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      {/* <ProductGrid products={mappedProducts} /> */}
      <ImageWrap>
        <img src={image} alt="Ofform" />
        {/* <Img
          fluid={data.image.childImageSharp.fluid}
          style={{
            // objectFit: 'contain',
            // position: 'absolute',
            // width: '100%',
            height: '100%',
            // bottom: 0,
          }}
          imgStyle={{
            height: '100%',
            objectFit: 'contain',
            objectPosition: '50% 100%',
          }}
        /> */}
      </ImageWrap>
    </>
  )
}

// export const query = graphql`
//   {
//     image: file(relativePath: { eq: "front2.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

export const query = graphql`
  {
    allPrismicHomepage {
      nodes {
        data {
          main_image {
            url
          }
        }
      }
    }
  }
`

export default IndexPage
