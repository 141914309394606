import styled from '@emotion/styled'

import { breakpoints } from '../../utils/styles'

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem; */
  width: 100%;
  @media (min-width: ${breakpoints.m}px) {
    /* grid-template-columns: repeat(3, 1fr); */
  }

  @media (min-width: ${breakpoints.xl}px) {
    /* grid-template-columns: repeat(4, 1fr); */
  }
`

export const Product = styled.div`
  display: flex;
  /* min-height: 100%; */
  flex-direction: column;
  padding: 0 1rem 3rem;
  width: 50%;
  @media (min-width: ${breakpoints.m}px) {
    width: 33.33%;
  }

  @media (min-width: ${breakpoints.xl}px) {
    width: 25%;
  }
`

export const Title = styled.span`
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`

export const PriceTag = styled.span`
  text-align: center;
  /* :before {
    content: '- ';
  } */
`

export const ImgWrap = styled.div`
  /* height: 50rem; */
  margin-bottom: 2rem;
`
