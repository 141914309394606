import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import StoreContext from '~/context/StoreContext'
import { Grid, Product, Title, PriceTag, ImgWrap } from './styles'
import { Img } from '~/utils/styles'

const ProductGrid = ({ products }) => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  // console.log(products)

  return (
    <Grid>
      {products ? (
        products.map(
          ({
            id,
            handle,
            title,
            priceRange,
            images: [firstImage],
            variants: [firstVariant],
          }) => (
            <Product key={id}>
              <Link to={`/product/${handle}/`}>
                <ImgWrap>
                  {firstImage && firstImage.originalSrc && (
                    <img src={firstImage.originalSrc} alt="" />
                    // <Img
                    //   fluid={firstImage.localFile.childImageSharp.fluid}
                    //   alt={handle}
                    //   style={{ height: '100%', objectFit: 'contain' }}
                    //   imgStyle={{ objectFit: 'contain' }}
                    // />
                  )}
                </ImgWrap>
              </Link>
              <Title>{title}</Title>
              <PriceTag>
                {getPrice(priceRange.minVariantPrice.amount)} -{' '}
                {getPrice(priceRange.maxVariantPrice.amount)}
                {/* {getPrice(firstVariant.price)} */}
              </PriceTag>
            </Product>
          )
        )
      ) : (
        <p>No Products found!</p>
      )}
    </Grid>
  )
}

export default ProductGrid
